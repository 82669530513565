<div class="image-container">
     <div *ngFor="let image of _albums; let i=index">
      <mat-card style="float: left; margin:10px;">
        <img mat-card-image [src]="image.thumb" (click)="open(i)"/>
        <br>
           <mat-card-subtitle class="red-text">{{image.title}}</mat-card-subtitle>
      </mat-card> 
     </div>
</div>

