<div style="margin-bottom: 8px; padding-bottom: 8px; border-bottom: 1px solid #ccc;">
    <form class="form-inline" (ngSubmit)="onSubmit()" [formGroup]="form">

        <div *ngFor="let property of properties" class="form-row">
            <app-dynamic-form-material-property [property]="property" [form]="form"></app-dynamic-form-material-property>
        </div>
 <!--       
        <div class="break"></div>

        <div *ngFor="let property of properties" class="form-row">
            <app-property [property]="property" [form]="form"></app-property>
        </div>
    -->
        <div>
            <button style="margin-top: 0px;" type="submit" [disabled]="!form.valid">Create</button>
        </div>

    </form>
</div>