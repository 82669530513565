import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { ImageService } from '../../_services/image.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent  {

  public _albums = [];

  constructor(private _lightbox: Lightbox, private imageService: ImageService) 
  {
    
    this.imageService.sendGetRequest().subscribe((data: any[])=>{
      this._albums = data;
    })  
  }
 
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
