import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { RouterModule } from '@angular/router';
import { PostsComponent } from 'src/app/modules/posts/posts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ReportComponent } from 'src/app/modules/report/report.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DynamicFormComponent }         from '../../modules/property/forms/dynamic-form.component';
import { DynamicFormQuestionComponent } from '../../modules/property/forms/dynamic-form-property.component';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DynamicFormMaterialPropertyComponent } from 'src/app/modules/property/forms/dynamic-form-material-property.component';

@NgModule({
  declarations: [
    DefaultComponent, 
    DashboardComponent,
    PostsComponent,
    ReportComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    DynamicFormMaterialPropertyComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatCardModule,
    MatFormFieldModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatCheckboxModule,
    BrowserAnimationsModule 
  ],
  providers: [  
    MatDatepickerModule,  
  ]
})
export class DefaultModule { }
