import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sideBarOpen = true;
  reportBarOpen = false;

  constructor() { }

  ngOnInit() {

  //  this.reportBarOpen = false;
      console.log('Default Init');

   }

  sideBarToggler() 
  {
    this.sideBarOpen = !this.sideBarOpen;
  }

  reportBarShow()
  {
    console.log('Show Report');

    this.reportBarOpen = true;
  }
}