import * as L from 'leaflet';

var LqOpacity = L.Control.extend({
    
    /**
     * Default control options
     */
    options: {
		collapsed: false,
        position: 'topright',
		autoZIndex: true,
		exclusiveGroups: [],
		expandableGroups: [],
		label: null
    },
    initialize: function (baseLayers, overlays, options) {
        
        L.Util.setOptions(this, options);

		this._layerControlInputs = [];
		this._layers = [];
		this._lastZIndex = 0;
		this._handlingClick = false;
		this._groupList = [];
		this._groupContainers = [];

		for (var i in baseLayers) {
			this._addLayer(baseLayers[i], i);
		}

		for (i in overlays) {
			this._addLayer(overlays[i], i, 'WMS', true);
        }
    },
    onAdd: function (map) {

        this._initLayout();

		this._update();

        this._map = map;
        
		map.on('zoomend', this._checkDisabledLayers, this);

		for (var i = 0; i < this._layers.length; i++) {
			this._layers[i].layer.on('add remove', this._onLayerChange, this);
		}

		return this._container;
	},

	addTo: function (map) {
        
        L.Control.prototype.addTo.call(this, map);
		// Trigger expand after Layers Control has been inserted into DOM so that it now has an actual height.
		return this;
	},

	onRemove: function () {
		this._map.off('zoomend', this._checkDisabledLayers, this);

		for (var i = 0; i < this._layers.length; i++) {
			this._layers[i].layer.off('add remove', this._onLayerChange, this);
		}
	},

	// @method addBaseLayer(layer: Layer, name: String): this
	// Adds a base layer (radio button entry) with the given name to the control.
	addBaseLayer: function (layer, name) {
		this._addLayer(layer, name);
		return (this._map) ? this._update() : this;
	},

	// @method addOverlay(layer: Layer, name: String): this
	// Adds an overlay (checkbox entry) with the given name to the control.
	addOverlay: function (layer, name, group) {
		this._addLayer(layer, name, group, true);
		return (this._map) ? this._update() : this;
	},

	// @method removeLayer(layer: Layer): this
	// Remove the given layer from the control.
	removeLayer: function (layer) {
		layer.off('add remove', this._onLayerChange, this);

		var obj = this._getLayer(L.Util.stamp(layer));
		if (obj) {
			this._layers.splice(this._layers.indexOf(obj), 1);
		}
		return (this._map) ? this._update() : this;
	},
	// @method expand(): this
	// Expand the control container if collapsed.
	expand: function () {
		L.DomUtil.addClass(this._container, 'leaflet-control-layers-expanded');
		this._section.style.height = null;
		var acceptableHeight = this._map.getSize().y - (this._container.offsetTop + 50);
		if (acceptableHeight < this._section.clientHeight) {
			L.DomUtil.addClass(this._section, 'leaflet-control-layers-scrollbar');
			this._section.style.height = acceptableHeight + 'px';
		} else {
			L.DomUtil.removeClass(this._section, 'leaflet-control-layers-scrollbar');
		}
		this._checkDisabledLayers();
		return this;
	},
  	_initLayout: function () {

		var className = 'leaflet-control-layers',
		    container = this._container = L.DomUtil.create('div', className),
		    collapsed = this.options.collapsed;

		// makes this work on IE touch devices by stopping it from firing a mouseout event when the touch is released
		//container.setAttribute('aria-haspopup', true);

		L.DomEvent.disableClickPropagation(container);
		L.DomEvent.disableScrollPropagation(container);

		var section = this._section = L.DomUtil.create('section', className + '-list');
/*
		if (collapsed) {
			this._map.on('click', this.collapse, this);

			if (!L.Browser.android) {
				L.DomEvent.on(container, {
					mouseenter: this.expand,
					mouseleave: this.collapse
				}, this);
			}
		}
*/
		var link = this._layersLink = L.DomUtil.create('a', className + '-toggle', container);
		link.href = '#';
		link.title = 'Layers';
/*
		if (L.Browser.touch) {
			L.DomEvent.on(link, 'click', L.DomEvent.stop);
			L.DomEvent.on(link, 'click', this.expand, this);
		} else {
			L.DomEvent.on(link, 'focus', this.expand, this);
		}
*/
		if (!collapsed) {
			this._expand();
		}

		this._baseLayersList = L.DomUtil.create('div', className + '-base', section);
		this._separatorA = L.DomUtil.create('div', className + '-separator', section);
		this._overlaysList = L.DomUtil.create('div', className + '-overlays', section);
		this._separatorB = L.DomUtil.create('div', className + '-separator', section);
		this._overlaysGroupList = L.DomUtil.create('div', className + '-overlays', section);

		container.appendChild(section);
    },
    _addLayer: function (layer, name, group, overlay) {

		if (this._map) {
			layer.on('add remove', this._onLayerChange, this);
		}

		var _layer = {
			layer: layer,
			name: name,
			overlay: overlay
		  };

	    this._layers.push(_layer);  

		group = group || '';
		var groupId = this._indexOf(this._groupList, group);
	
		if (groupId === -1) {
		  groupId = this._groupList.push(group) - 1;
		}

		var exclusive = (this._indexOf(this.options.exclusiveGroups, group) !== -1);
	
		_layer.group = {
		  name: group,
		  id: groupId,
		  exclusive: exclusive
		};

		if (this.options.autoZIndex && layer.setZIndex) {
			this._lastZIndex++;
			layer.setZIndex(this._lastZIndex);
		}

		this._expandIfNotCollapsed();
    },
    _getLayer: function (id) {

		for (var i = 0; i < this._layers.length; i++) {

			if (this._layers[i] && L.Util.stamp(this._layers[i].layer) === id) {
				return this._layers[i];
			}
		}
	},
	_update: function () {

		if (!this._container) { return this; }

		L.DomUtil.empty(this._baseLayersList);
		L.DomUtil.empty(this._overlaysList);
		L.DomUtil.empty(this._overlaysGroupList);

		this._groupContainers.length = 0;

		this._layerControlInputs = [];
		var baseLayersPresent, overlaysPresent, i, obj, baseLayersCount = 0;

		for (i = 0; i < this._layers.length; i++) {

			obj = this._layers[i];
			this._addItem(obj);
			overlaysPresent = overlaysPresent || obj.overlay;
			baseLayersPresent = baseLayersPresent || !obj.overlay;
			baseLayersCount += !obj.overlay ? 1 : 0;
		}

		// Hide base layers section if there's only one layer.
		if (this.options.hideSingleBase) {

			baseLayersPresent = baseLayersPresent && baseLayersCount > 1;
			this._baseLayersList.style.display = baseLayersPresent ? '' : 'none';
		}

		this._separatorA.style.display = overlaysPresent && baseLayersPresent ? '' : 'none';

		return this;
    },
	_onLayerChange: function (e) {
		if (!this._handlingClick) {
			this._update();
		}

		var obj = this._getLayer(L.Util.stamp(e.target));

		// @namespace Map
		// @section Layer events
		// @event baselayerchange: LayersControlEvent
		// Fired when the base layer is changed through the [layers control](#control-layers).
		// @event overlayadd: LayersControlEvent
		// Fired when an overlay is selected through the [layers control](#control-layers).
		// @event overlayremove: LayersControlEvent
		// Fired when an overlay is deselected through the [layers control](#control-layers).
		// @namespace Control.Layers
		var type = obj.overlay ?
			(e.type === 'add' ? 'overlayadd' : 'overlayremove') :
			(e.type === 'add' ? 'baselayerchange' : null);

		if (type) {
			this._map.fire(type, obj);
		}
	},
	// IE7 bugs out if you create a radio dynamically, so you have to do it this hacky way (see http://bit.ly/PqYLBe)
	_createRadioElement: function (name, checked) {

		var radioHtml = '<input type="radio" class="leaflet-control-layers-selector" name="' +
				name + '"' + (checked ? ' checked="checked"' : '') + '/>';

		var radioFragment = document.createElement('div');
		radioFragment.innerHTML = radioHtml;

		return radioFragment.firstChild;
	},
    _addItem: function (obj) 
    {
 		var label = document.createElement('label'),
		    checked = this._map.hasLayer(obj.layer),
			container, groupContainer,
			input, opacity;
            
        var hasOpacity = obj.overlay && !(typeof obj.layer._url === 'undefined');

        if (obj.overlay) 
        {
			input = document.createElement('input');
			input.type = 'checkbox';
			input.className = 'leaflet-control-layers-selector';
            input.defaultChecked = checked;
            
            if (hasOpacity)
            {
                opacity = document.createElement('input');
                opacity.type = 'range';
                opacity.className = 'leaflet-control-layers-range';
                opacity.min = 0;
                opacity.max = 100;
                opacity.value = 50;
            }
        } 
        else 
        {
			input = this._createRadioElement('leaflet-base-layers_' + L.Util.stamp(this), checked);
		}

		this._layerControlInputs.push(input);

        input.layerId = L.Util.stamp(obj.layer);

        L.DomEvent.on(input, 'click', this._onInputClicked, this);
        
        if (hasOpacity)
        {
           opacity.layerId = input.layerId;

           L.DomEvent.on(opacity, 'click', this._onOpacityChange, this);
        }

		var name = document.createElement('span');
		name.innerHTML = ' ' + obj.name;

		// Helps from preventing layer control flicker when checkboxes are disabled
		// https://github.com/Leaflet/Leaflet/issues/2771
        var holder = document.createElement('div');

        label.appendChild(holder);
        
        if (hasOpacity)
        {
            var holderLayer   = document.createElement('div');
            var holderOpacity = document.createElement('div');

            holder.appendChild(holderLayer);
            holder.appendChild(holderOpacity);

            holderLayer.appendChild(input);
            holderLayer.appendChild(name);

            holderOpacity.appendChild(opacity);
        }
        else
        {
            holder.appendChild(input);
            holder.appendChild(name);
		}
		
		if (obj.overlay && !hasOpacity)
		{
			groupContainer = this._groupContainers[obj.group.id];

			// Create the group container if it doesn't exist
			if (!groupContainer) 
			{
			   groupContainer = document.createElement('div');
			   groupContainer.className = 'leaflet-control-layers-group';
	  
			   var groupLabel = document.createElement('label');
			   groupLabel.className = 'leaflet-control-layers-group-label';

			   var groupName = document.createElement('span');
			   groupName.className = 'leaflet-control-layers-group-name';
			   groupName.innerHTML = obj.group.name;
			   groupLabel.appendChild(groupName);
	   
			   groupContainer.appendChild(groupLabel);
	   
			   this._groupContainers[obj.group.id] = groupContainer;
			}

			groupContainer.appendChild(label);
			
			this._overlaysGroupList.appendChild(groupContainer);
		}
		else
		{
			container = (obj.overlay) ? this._overlaysList : this._baseLayersList;

            container.appendChild(label);
		}

        this._checkDisabledLayers();
        
		return label;
    },
    _onOpacityChange: function (e)
    {
       var layerId = e.toElement.layerId;
       var value = e.toElement.valueAsNumber;

       this._handlingClick = true;

       var layer = this._getLayer(layerId).layer;

       layer.setOpacity(value / 100);
    },
	_onInputClicked: function () {

		var inputs = this._layerControlInputs,
		    input, layer;
		var addedLayers = [],
		    removedLayers = [];

		this._handlingClick = true;

		for (var i = inputs.length - 1; i >= 0; i--) {
			input = inputs[i];
			layer = this._getLayer(input.layerId).layer;

            if (input.checked) 
            {
				addedLayers.push(layer);
            } 
            else if (!input.checked) 
            {
				removedLayers.push(layer);
			}
		}

		// Bugfix issue 2318: Should remove all old layers before readding new ones
        for (i = 0; i < removedLayers.length; i++) 
        {
            if (this._map.hasLayer(removedLayers[i])) 
            {
				this._map.removeLayer(removedLayers[i]);
			}
		}
        for (i = 0; i < addedLayers.length; i++) 
        {
            if (!this._map.hasLayer(addedLayers[i])) 
            {
				this._map.addLayer(addedLayers[i]);
			}
		}

		this._handlingClick = false;

		this._refocusOnMap();
	},
    _checkDisabledLayers: function () 
    {
		var inputs = this._layerControlInputs,
		    input,
		    layer,
		    zoom = this._map.getZoom();

        for (var i = inputs.length - 1; i >= 0; i--) 
        {
			input = inputs[i];
			layer = this._getLayer(input.layerId).layer;
			input.disabled = (layer.options.minZoom !== undefined && zoom < layer.options.minZoom) ||
			                 (layer.options.maxZoom !== undefined && zoom > layer.options.maxZoom);

		}
    },
    _expand: function () {
		// Backward compatibility, remove me in 1.1.
		return this.expand();
    },
    _expandIfNotCollapsed: function () {
		if (this._map && !this.options.collapsed) {
			this.expand();
		}
		return this;
	},
	_indexOf: function (arr, obj) {
		for (var i = 0, j = arr.length; i < j; i++) {
		  if (arr[i] === obj) {
			return i;
		  }
		}
		return -1;
	  }
});

export var lqopacity = function (baseLayers, overlays, options) {
    return new LqOpacity(baseLayers, overlays, options);
};