<div [formGroup]="form">
    <!--  <label class="label-margin" [attr.for]="property.key">{{property.label}}</label> -->
  
      <div [ngSwitch]="property.controlType">
  
          <mat-checkbox *ngSwitchCase="'checkbox'" class="input-margin" style="margin-top: -8px;" [id]="property.key" [formControlName]="property.key">{{property.label}}</mat-checkbox>
      
          <mat-form-field *ngSwitchCase="'dropdown'" class="input-margin">
              <mat-label>Choose a report</mat-label>
              <mat-select [id]="property.key" [formControlName]="property.key">
                <mat-option *ngFor="let opt of property.options" [value]="opt.key">
                  {{opt.value}}
                </mat-option>
              </mat-select>
          </mat-form-field>
  
          <mat-form-field *ngSwitchCase="'datetime'" class="input-margin">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" [formControlName]="property.key" [id]="property.key">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          
      </div>
  
      <div class="errorMessage" *ngIf="!isValid">{{property.label}} is required</div>
  </div>