import * as L from 'leaflet';

var LqLegend = L.Control.extend({
    
    /**
     * Default control options
     */
    options: {
		collapsed: false,
        position: 'bottomright',
		autoZIndex: true,
    },
    initialize: function (options) {
        
        L.Util.setOptions(this, options);

    },
    onAdd: function (map) {

        this._initLayout();

  		return this._container;
	},
	addTo: function (map) {
        
        L.Control.prototype.addTo.call(this, map);

        // Trigger expand after Layers Control has been inserted into DOM so that it now has an actual height.
		return this;
    },
    addLegend: function(legend)
    {
        this._addLegend(legend);

        return this;
    },
	_initLayout: function () {

		var className = 'leaflet-control-layers',
		    container = this._container = L.DomUtil.create('div', className),
		    collapsed = this.options.collapsed;

		L.DomEvent.disableClickPropagation(container);
		L.DomEvent.disableScrollPropagation(container);

		var section = this._section = L.DomUtil.create('section', className + '-list');

        var link = this._layersLink = L.DomUtil.create('a', className + '-toggle', container);
		link.href = '#';
        link.title = 'Layers';
        
        if (!collapsed) {
			this._expand();
        }
        
        this._legendContainer = L.DomUtil.create('div', className + '-overlays', section);

        container.appendChild(section);
    },
    _addLegend: function(legend)
    {
        var className = 'leaflet-control-layers';

        var legendContainer = this._legendContainer;

        var legendItems = legend;  

        var collections = legendItems["legendcollection"];

        var label, items;
        var content = [];
        var legendpart, separator;

        collections.forEach(legenditem => {

            label = legenditem["label"];
            items = legenditem["items"];

            content = [`<strong>${label}</strong>`];

            items.forEach(item => {
            
                content.push(`<span class="circle" style="background: ${item.color}"></span> ${item.label}`);
            });

            legendpart =  L.DomUtil.create('div', className + '-legend'); 
            legendpart.style.display = legenditem.enabled ? 'inline-block' : 'none';
            legendpart.innerHTML = content.join('<br>');
            
            legendContainer.appendChild(legendpart);

//            separator =  L.DomUtil.create('div', className + '-separator-vertical', section);
//            legendContainer.appendChild(separator);
        });
    },
    expand: function () {
        
        L.DomUtil.addClass(this._container, 'leaflet-control-layers-expanded');
		this._section.style.height = null;
		var acceptableHeight = this._map.getSize().y - (this._container.offsetTop + 50);
		if (acceptableHeight < this._section.clientHeight) {
			L.DomUtil.addClass(this._section, 'leaflet-control-layers-scrollbar');
			this._section.style.height = acceptableHeight + 'px';
		} else {
			L.DomUtil.removeClass(this._section, 'leaflet-control-layers-scrollbar');
		}
		
		return this;
	},
    _expand: function () {
		// Backward compatibility, remove me in 1.1.
		return this.expand();
    },
    _expandIfNotCollapsed: function () {
		if (this._map && !this.options.collapsed) {
			this.expand();
		}
		return this;
	},
});

export var lqlegend = function (options) {
    return new LqLegend(options);
};