<div>
  <app-dynamic-form [properties]="properties$ | async"  (createReport)="reportRequest($event)"></app-dynamic-form>
</div>

<div class="report-controls">
  <div *ngIf="isLoaded" style="text-align: center;">
      <button (click)="prevPage()" [disabled]="page === 1">Prev</button>
      <span>{{ page }} / {{ totalPages }}</span>
      <button (click)="nextPage()" [disabled]="page === totalPages">Next</button>
  </div>
</div>

<div class="report-container">
  <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="true" [show-all]="false"
    [page]="page" (after-load-complete)="afterLoadComplete($event)" style="display: block;"></pdf-viewer>
</div>