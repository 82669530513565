<mat-nav-list>
    <div class="profile-card">
        <div class="header">
            <h2>Brok IT Consult</h2>
            <h4>info@brok-it.nl</h4>
        </div>
 <!--       <img src="https://brok-it.nl/images/brok-it-wt.png" alt="Brok IT Consult"> -->
    </div>

    <mat-divider></mat-divider>

    <h2 matSubheader>Pages</h2>

 <!--   <a mat-list-item routerLinkActive="list-item-active" routerLink="/">Dashboard</a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/posts">Images</a>
-->    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">Reports</a>
   
    <mat-divider></mat-divider>
<!--
    <h2 matSubheader>Tools</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>import_contacts</mat-icon>
        Contacts
    </a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contacts">
        <mat-icon>contact_phone</mat-icon>
        Leads
    </a>
-->
</mat-nav-list>