<div [formGroup]="form">
    <label class="label-margin" [attr.for]="property.key">{{property.label}}</label>
    <div [ngSwitch]="property.controlType">

        <input *ngSwitchCase="'textbox'" class="input-margin-text" [formControlName]="property.key" [id]="property.key" [type]="property.type">

        <input *ngSwitchCase="'week'" class="input-margin" [formControlName]="property.key" [id]="property.key" [type]="property.type">

        <input *ngSwitchCase="'datetime'" class="input-margin" [formControlName]="property.key" [id]="property.key" [type]="property.type">

        <input *ngSwitchCase="'color'" class="input-margin" [formControlName]="property.key" [id]="property.key" [type]="property.type">

        <select *ngSwitchCase="'dropdown'" class="select-margin" [formControlName]="property.key" [id]="property.key">
            <option *ngFor="let opt of property.options" [value]="opt.key">{{opt.value}}</option>
        </select>

        <input *ngSwitchCase="'checkbox'" class="input-margin" [formControlName]="property.key" [id]="property.key" [type]="property.type">

    </div>

    <div class="errorMessage" *ngIf="!isValid">{{property.label}} is required</div>
</div>