import { Component, Input, OnInit, EventEmitter, Output }  from '@angular/core';
import { FormGroup }                 from '@angular/forms';
import { ProgressSpinnerMode }       from '@angular/material/progress-spinner';
import { ThemePalette }              from '@angular/material/core';
import { PropertyBase }              from '../properties/property-base';
import { PropertyControlService }    from '../services/property-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [ PropertyControlService ],
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() properties: PropertyBase<string>[] = [];

  @Output() createReport: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  payLoad = '';

  color: ThemePalette = 'primary';

  constructor(private props: PropertyControlService) {  }

  ngOnInit() {
    this.form = this.props.toFormGroup(this.properties);
  }

  onSubmit() {

    this.createReport.emit({ event: this.form.getRawValue() });

    console.log(this.form.getRawValue());
 //   this.payLoad = JSON.stringify(this.form.getRawValue());
 //   this.mode = 'indeterminate';
  }
}