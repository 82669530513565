import { PropertyBase } from './property-base';


export class DateTimeProperty extends PropertyBase<Date> {
  
  controlType = 'datetime';
  type = 'date';

  constructor(options: {} = {}) {
    super(options);
  }
}