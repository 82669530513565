import { CommonModule } from '@angular/common';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PropertyService } from '../property/services/property-request.service';
import { PropertyBase } from '../property/properties/property-base';
import { Observable } from 'rxjs';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [PropertyService]
})
export class ReportComponent implements OnInit {

  @Output() showReportBar: EventEmitter<any> = new EventEmitter();

  properties$: Observable<PropertyBase<any>[]>;

  public pdfSrc = ""; //"https://service.brok-it.nl/report.php";
  public page: number = 1;
  public totalPages: number;
  public isLoaded: boolean = false;

  mode: ProgressSpinnerMode = 'indeterminate';
  value = 40;

  constructor(service: PropertyService) {

    this.properties$ = service.getProperties();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  ngOnInit()
  {
    //var legend = new Q.lqlegend({}).addTo(map);
  }

  toggle()
  {
    console.log('Emit Event from toggle');

    this.showReportBar.emit();

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  reportRequest($event){

    var reportType = $event.event.group;

    console.log(reportType);

    this.pdfSrc = "https://service.brok-it.nl/report.php?report=" +  $event.event.group;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
}
