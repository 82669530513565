import { Component, Input } from '@angular/core';
import { FormGroup }        from '@angular/forms';
import { PropertyBase }     from '../properties/property-base';

@Component({
  selector: 'app-dynamic-form-material-property',
  templateUrl: './dynamic-form-material-property.component.html',
  styleUrls: ['./dynamic-form-material-property.component.scss']
})
export class DynamicFormMaterialPropertyComponent {
  @Input() property: PropertyBase<string>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.property.key].valid; }

  constructor() { }

}



