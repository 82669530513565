import { Component, Input } from '@angular/core';
import { FormGroup }        from '@angular/forms';

import { PropertyBase }     from '../properties/property-base';

@Component({
  selector: 'app-property',
  styleUrls: ['./dynamic-form-property.component.scss'],
  templateUrl: './dynamic-form-property.component.html'
})
export class DynamicFormQuestionComponent {
  @Input() property: PropertyBase<string>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.property.key].valid; }
}