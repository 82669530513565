import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import * as M from 'leaflet-wfst';
import { icon, Marker } from 'leaflet';
import { MarkerService } from '../../_services/marker.service';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  private map;

  constructor(private markerService: MarkerService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {

    this.initMap();

    var layerControl = this.markerService.createControlView(this.map);

    this.markerService.getRouteList(this.map, layerControl);
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [51.960379, 6.323877],
      zoom: 13
    });

  }
}
