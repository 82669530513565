import { Injectable }       from '@angular/core';

import { PropertyBase }     from '../properties/property-base';
import { DropdownProperty } from '../properties/property-dropdown';
import { TextboxProperty }  from '../properties/property-textbox';
import { DateTimeProperty } from '../properties/property-datetime';
import { WeeknumberProperty} from '../properties/property-weeknumber';
import { of } from 'rxjs';
import { ColorPickerProperty } from '../properties/property-color-picker';
import { CheckboxProperty } from '../properties/property-checkbox';
import {RowSeparatorProperty } from '../properties/property-row-separator';

@Injectable()
export class PropertyService {

  // TODO: get from a remote source of question metadata
  getProperties() {

    let properties: PropertyBase<any>[] = [
/*
      new CheckboxProperty({
        key: 'layer1',
        label: 'Strengtype',
        value: 'false',
        required: true,
        order: 0
      }),
*/      new DropdownProperty({
        key: 'group',
        label: 'Groep',
        options: [
          {key: 'semex',  value: 'Fertility Report'},
          {key: 'castro',   value: 'Productivity Report'},
          {key: 'hortimax',   value: 'Hortimax Report'},
          {key: 'logistics',  value: 'Logistics Demo'},
        ],
        order: 1
      }),
      new DateTimeProperty({
        key: 'reportDate',
        label: 'Rapport Datum',
        required: false,
        order: 2
      }), 

/*
      new WeeknumberProperty({
        key: 'weeknumber',
        label: 'Weeknummer',
        required: false,
        order: 2
      }),  
*/

      /*
      new TextboxProperty({
        key: 'fileName',
        label: 'Bestandsnaam',
        value: 'Week 18 - Overview',
        required: true,
        order: 3
      }),
/*
      new ColorPickerProperty({
        key: 'propColor',
        label: 'Color',
        value: '#fedcba',
        required: true,
        order: 5
      }),
*/      
    ];

    return of(properties.sort((a, b) => a.order - b.order));
  }
}