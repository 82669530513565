import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import '../../../node_modules/leaflet-wfst/dist/leaflet-wfst.src.js';
//import '../_extensions/map-lqopacity-control';
import * as P from '../../assets/js/map-lqopacity-control.js';
import * as Q from '../../assets/js/map-lqlegend-control.js';

declare module 'leaflet' { var WFS:any }

@Injectable({
  providedIn: 'root'
})
export class MarkerService {

  capitals: string = '/assets/data/stroombroek.json';
  expedition: string = '/assets/data/expeditie.json';
  hiking: string = '/assets/data/GPS_PT.JSON';
  list: string = '/assets/data/list.json';
  network: string = '/assets/data/Netwerk_Knooppunt.geojson';

  private overlays = {};

  private index = 0;

  constructor(private http: HttpClient) {
  }

  private getLegendItemsFromServer(): any {

    return {
      "legendcollection": [
        {
          "label": "Afvoertype",
          "id": "lg-id001",
          "enabled": true,
          "items":
            [
              {
                "label": "Vuil water",
                "color": "#de2d26"
              },
              {
                "label": "Regenwater",
                "color": "#377eb8"
              },
              {
                "label": "Gemengd",
                "color": "#4daf4a"
              },
              {
                "label": "Onbekend",
                "color": "#984ea3"
              }
            ]
        },
        {
          "label": "Strengtype",
          "id": "lg-id002",
          "enabled": false,
          "items":
            [
              {
                "label": "Persleiding",
                "color": "#aa00aa"
              },
              {
                "label": "DWA riool",
                "color": "#00aaaa"
              },
              {
                "label": "Onbekend",
                "color": "#aaaa00"
              }
            ]
        },
        {
          "label": "Constructiejaar",
          "id": "lg-id003",
          "enabled": true,
          "items":
            [
              {
                "label": "1950-1970",
                "color": "#ff4400"
              },
              {
                "label": "1970-1985",
                "color": "#aa8800"
              },
              {
                "label": "1985-2000",
                "color": "#88cc00"
              },
              {
                "label": "2000-2010",
                "color": "#44cc00"
              },
              {
                "label": "2010-2020",
                "color": "#22ee00"
              }
            ]
        }
      ]
    };
  }

  private getItemListFromServer(): any {
    return {
      "overlaycollection": [
        {
          "type": "geojson",
          "name": "Stroombroek",
          "url": "https://service.brok-it.nl/overlay.php?layer=stroombroek.json",
        //  "url":"http://94.168.57.179/maps/westmaas_stelsel.layer1.json",
          "radius": 4,
          "fillColor": "red",
          "color": "blue",
          "weight": 1,
          "opacity": 1,
          "fillOpacity": 0.8,
          "show": true
        },
        {
          "type": "geojson",
          "name": "Expeditie",
          "url": "https://service.brok-it.nl/overlay.php?layer=expeditie.json",
          "radius": 4,
          "fillColor": "yellow",
          "color": "blue",
          "weight": 1,
          "opacity": 1,
          "fillOpacity": 0.8,
          "show": false
        }
        ,
        {
          "type": "geojson",
          "name": "Walking",
          "url": "https://service.brok-it.nl/overlay.php?layer=walk.json",
          "radius": 4,
          "fillColor": "white",
          "color": "blue",
          "weight": 1,
          "opacity": 1,
          "fillOpacity": 0.8,
          "show": true
        },
        {
          "type": "geojson",
          "name": "Cycling",
          "url": "https://service.brok-it.nl/overlay.php?layer=cycling.json",
          "radius": 4,
          "fillColor": "lightgreen",
          "color": "blue",
          "weight": 1,
          "opacity": 1,
          "fillOpacity": 0.8,
          "show": true
        },
        /*
        {
          "type": "geojson",
          "name": "Maas",
          "url": "https://service.brok-it.nl/overlay.php?layer=westmaas_stelsel.json",
          "radius": 4,
          "fillColor": "lightgreen",
          "color": "blue",
          "weight": 1,
          "opacity": 1,
          "fillOpacity": 0.8,
          "show": true
        },
        {
          "type": "wfs",
          "name": "Nationale Parken",
          "url": "https://geodata.nationaalgeoregister.nl/nationaleparken/wfs",
          "layer": "nationaleparken:nationaleparken",
          "version": "2.0.0",
          "color": "blue",
          "show": true,
          "srs": "EPSG:4326",
          "bbox": "13014,306243,286599,623492"
        },
              {
                 "type": "wfs",
                 "name": "Nationale Parken 2",
                 "url": "https://geodata.nationaalgeoregister.nl/nationaleparken/wfs",
                 "layer": "nationaleparken:nationaleparken",
                 "version" : "2.0.0",
                 "color": "blue",
                 "show": true,
                 "srs" : "CRS:84",
                 "bbox": "3.955078125,51.582189932691456,7.41302490234375,52.83761742439466"
               },
        {
          "type": "wfs",
          "name": "Panden",
          "url": "https://geodata.nationaalgeoregister.nl/bag/wfs",
          "layer": "bag:pand",
          "color": "blue",
          "version": "2.0.0",
          "show": true,
          "srs": "EPSG:4326",
          "bbox": "232525,583269,234265,584240"
        }
             ,
               {
               "type": "wfs",
               "name": "Water",
               "url": "https://geodata.nationaalgeoregister.nl/rioned/gwsw/wfs/v1_0",
               "layer": "gwsw:beheer_leiding",
               "version" : "1.1.0",
               "color": "blue",
               "show": true,
               "srs" : "CRS:84",
               "bbox": "3.955078125,51.582189932691456,7.41302490234375,52.83761742439466"
              }*/
      ]
    };
  }

  //

  private createGeoJsonOverlay(map: L.Map, url: string, name: string, color: string) {
    var geojsonMarkerOptions = {
      radius: 4,
      fillColor: color,
      color: 'blue',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    };

    var layerGroup = L.layerGroup().addTo(map);

    this.http.get(url).subscribe((res: any) => {
      for (const c of res.features) {

 //       console.log(c);

        const lat = c.geometry.coordinates[0];
        const lon = c.geometry.coordinates[1];
        const marker = L.circleMarker([lon, lat], geojsonMarkerOptions); //addTo(map);
        layerGroup.addLayer(marker);
      }
    });

    this.overlays[name] = layerGroup;
  }

  private CreateJsonOverlayFromUrl(map: L.Map, control: P.LqOpacity, url: string, name: string, color: string) {
    var layerStyle = {
      "color": "#ff7800",
      "weight": 5,
      "opacity": 0.65
    };

    var geojsonMarkerOptions = {
      radius: 4,
      fillColor: color,
      color: 'blue',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    };

    this.http.get(url).subscribe((res: any) => {

      var layer = L.geoJSON(res, {
        pointToLayer: function (feature, latlng) {
          return L.circleMarker(latlng, geojsonMarkerOptions);
        },
        style: function (feature) {
          var lineColor = (parseInt(feature.properties.Jaar) < 2000) ? '#FF0000' : '#00FF00';
          var lineWeight = (parseInt(feature.properties.BodemBrdt) < 100) ? 4 : 8;

          if (feature.geometry.type === "LineString") return { color: lineColor, weight: lineWeight };
        }
      }).addTo(map);

      control.addOverlay(layer, name, (this.index++ < 2) ? 'Group 1' : 'Group 2');
    });
  }

  private CreateWfsOverlayFromUrl(map: L.Map, control: P.LqOpacity, url: string, name: string,
    version: string, layer: string, srs: string, bounding: string) {
    var defaultParams = {
      service: 'WFS',
      version: version,
      request: 'GetFeature',
      typeName: layer,
      outputFormat: 'application/json',
      subtype: 'geojson',
      srsName: srs,
      bbox: bounding
    };

    var request = (url + L.Util.getParamString(defaultParams));

    //   console.log(request);

    this.http.get(request).subscribe((res: any) => {

      // console.log(res);

      var layer = L.geoJSON(res, {
        style: function (feature) {
          return { color: '#00BB00', opacity: 0.5 };
        }
      }); //.addTo(map);

      control.addOverlay(layer, name, 'WFS');
    });
  }

  private CreateWfsOverlayFromUrlOnly(map: L.Map, control: P.LqOpacity, ) {
    var url = 'https://geodata.nationaalgeoregister.nl/rioned/gwsw/wfs/v1_0';

    var defaultParams = {
      service: 'WFS',
      version: '2.0.0',
      request: 'GetFeature',
      typeName: 'gwsw:beheer_leiding',
      outputFormat: 'application/json',
      subtype: 'geojson',
      srsName: 'EPSG:4326',
    };

    var request = (url + L.Util.getParamString(defaultParams));

    this.http.get(request).subscribe((res: any) => {

      console.log(res);

      var layer = L.geoJSON(res, {
        style: function (feature) {
          return { color: '#00BB00', opacity: 0.5 };
        }
      }); //.addTo(map);

      control.addOverlay(layer, 'Leidingen', 'WFS');
    });
  }
  
  CreateWfs(map: L.Map)
  {
      var boundaries = new L.WFS({
        url: 'https://service.brok-it.nl/gwsw.php',
        typeNS: 'gwsw',
        typeName: 'Default_Gebied',
//        crs: L.CRS.EPSG28992,
        style: {
            color: 'blue',
            weight: 2,
            opacity: 1
        }
    }).addTo(map);

    console.log(boundaries);

//    this.createGeoJsonOverlay(map,this.network,"Netwerk","red");
  }

  getRouteList(map: L.Map, control: any): void 
  {
    var collection = this.getItemListFromServer();

    var items = collection["overlaycollection"];

    items.forEach(element => {

      if (element.show == true && element.type === 'geojson') {
        this.CreateJsonOverlayFromUrl(map, control, element.url, element.name, element.fillColor);
      }

      if (element.show == true && element.type === 'wfs') {
        this.CreateWfsOverlayFromUrl(map, control, element.url, element.name, element.version, element.layer, element.srs, element.bbox);
      }
    });

   // this.CreateWfsOverlayFromUrlOnly(map, control);

    // this.CreateWfs(map);
  }

  createControlView(map: L.Map): L.Control {
    var osmLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>',
      otmLink = '<a href="http://opentopomap.org/">OpenTopoMap</a>';

    var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      osmAttrib = '', // '&copy; ' + osmLink + ' Contributors',
      otmUrl = 'http://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      otmAttrib = '' //'&copy; '+otmLink+' Contributors';

    var osmMap = L.tileLayer(osmUrl, { attribution: osmAttrib }).addTo(map),
      otmMap = L.tileLayer(otmUrl, { attribution: otmAttrib });

    var baseLayers = {
      "Streetmap": osmMap,
      "Topogrophy": otmMap
    };

    var leefbaro = L.tileLayer.wms("https://geodata.nationaalgeoregister.nl/ahn1/wms", {
      layers: 'ahn1_100m',
      format: 'image/png',
      opacity: .5,
      transparent: true
    });

    this.overlays["WMS"] = leefbaro;

    var leefbag = L.tileLayer.wms("https://geodata.nationaalgeoregister.nl/bag/wms", {
      layers: 'bag',
      format: 'image/png',
      opacity: .5,
      transparent: true
    });

    this.overlays["BAG"] = leefbag;

    var legend = new Q.lqlegend({}).addTo(map);

    var items = this.getLegendItemsFromServer();

    //console.log(items);

    //legend.addLegend(items);

    return P.lqopacity(baseLayers, this.overlays, { collapsed: false }).addTo(map);
  }
}